export const STAGE = process.env.REACT_APP_STAGE || 'local';
export const API_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:5000';
export const CDN_BASE_URL = process.env.REACT_APP_CDN_BASE_URL;
export const SHARE_BASE_URL = process.env.REACT_APP_SHARE_BASE_URL || 'http://localhost:3000';

export const APP_STORE_URL = process.env.REACT_APP_APP_STORE_URL;

export const LOGO_URL = process.env.REACT_APP_LOGO_URL;
export const GA_ID = process.env.REACT_APP_GA_ID;
export const FP_ID = process.env.REACT_APP_FP_ID;
export const GTM_ID = process.env.REACT_APP_GTM_ID;

export const COMPANY_INFO_URL = process.env.REACT_APP_COMPANY_INFO_URL || '';
export const CONTACT_URL = process.env.REACT_APP_CONTACT_URL || '';
export const B2B_URL = process.env.REACT_APP_B2B_URL || '';
export const DAUM_POSTCODE_URL = process.env.REACT_APP_DAUM_POSTCODE_URL || '';

export const ENABLED_PAYMENT_METHOD = process.env.REACT_APP_PAYMENT_METHOD || '';
export const IMP_ID = process.env.REACT_APP_IMP_ID || '';
export const IMP_MERCHANT_ID_CLASS = process.env.REACT_APP_IMP_MERCHANT_ID_CLASS || '';
export const IMP_MERCHANT_ID_COMMERCE = process.env.REACT_APP_IMP_MERCHANT_ID_COMMERCE || '';
export const IMP_PAYMENT_HOOK_PATH = process.env.REACT_APP_IMP_PAYMENT_HOOK_PATH || '';

export const TOSS_PAYMENTS_CLIENT_KEY = process.env.REACT_APP_TOSS_PAYMENTS_CLIENT_KEY || '';
export const TOSS_PAYMENTS_SECRET_KEY = process.env.REACT_APP_TOSS_PAYMENTS_SECRET_KEY || '';

export const KAKAO_TUTOR_CENTER_URL = process.env.REACT_APP_KAKAO_TUTOR_CENTER_URL || '';
export const KAKAO_PARENT_CENTER_URL = process.env.REACT_APP_KAKAO_PARENT_CENTER_URL || '';
export const ADD_CLASS_GOOGLE_FORM_URL = process.env.REACT_APP_ADD_CLASS_GOOGLE_FORM_URL || '';
export const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY || '';
export const KAKAO_LOGIN_REDIRECT_URL = process.env.REACT_APP_KAKAO_LOGIN_REDIRECT_URL || '';

export const NAVER_APP_ID = process.env.REACT_APP_NAVER_APP_ID || '';
export const NAVER_LOGIN_REDIRECT_URL = process.env.REACT_APP_NAVER_LOGIN_REDIRECT_URL || '';

export const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID || '';
export const APPLE_LOGIN_KEY = process.env.REACT_APP_APPLE_LOGIN_KEY || '';
export const APPLE_LOGIN_REDIRECT_URL = process.env.REACT_APP_APPLE_LOGIN_REDIRECT_URL || '';
export const APP_BASE_PATH = process.env.REACT_APP_APP_BASE_PATH || '';
export const GA4_ID = process.env.REACT_APP_GA4_ID;
export const TUTOR_URL = process.env.REACT_APP_TUTOR_URL || '';
export const PARTNER_URL = process.env.REACT_APP_PARTNER_URL || '';

export const NATIVE_APP_SCHEME = process.env.REACT_APP_NATIVE_APP_SCHEME || 'igogo://';

export const WEB_VERSION = 'v0.1.84';
